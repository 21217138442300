<template>
<div id="contentwrap">
    <PageTitle />
    <article class="row ">
        <div class="inner">
            <header>
                <h1 class="title">Contact Meredith</h1>
            </header>
            <hr>
            <div class="contact-container">
                <div class="photo">
                    <div>
                        <img src="/images/contact-page.jpg" alt="Meredith Portrait"/>
                    </div>
                    <a href="https://www.zillow.com/profile/Meredithschlosser/#reviews" class="badge" target="_blank" aria-label="Go to Meredith Zillow Profile">
                        <img class="star-badge" src="/images/badge-zillow.png" alt="5 Stars Agent">
                    </a>
                </div>
                <div class="contact-info">
                    <h3>
                        Meredith Schlosser
                    </h3>
                    <h6>CalDRE: 01910316</h6>
                    <div>
                        <a class="contact-anchor" href="tel:310.923.5811">310.923.5811</a><span> (mobile)</span>
                    </div>
                    <a class="contact-anchor" href="mailto:meredithschlosser@gmail.com">meredithschlosser@gmail.com</a>
                    <div class="social-container">
                        <a class="social-icon" href="https://www.instagram.com/meredithrealestate/" target="_blank">
                            <font-awesome-icon :icon="['fab', `instagram`]" />
                        </a>
                        <a class="social-icon" href="https://www.linkedin.com/in/meredith-schlosser-1167234/" target="_blank">
                            <font-awesome-icon :icon="['fab', `linkedin`]" />
                        </a>
                        <a class="social-icon" href="https://www.facebook.com/meredithschlosserrealestate/" target="_blank">
                            <font-awesome-icon :icon="['fab', `facebook-square`]" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </article>
</div>
</template>

<script>

import PageTitle from "../components/PageTitle"

export default {
    name: "Contact",
    components: {
        PageTitle
    },
    metaInfo: {
        title: 'Contact',
        titleTemplate: '%s Meredith Schlosser',
        meta: [{
            name: 'description',
            content: "Contact Meredith Schlosser. A top Real Estate Agent in Los Angeles and Beverly Hills through Email, Phone, In Person, or Contact Form."
        },
        {
            name: 'author',
            content: "Meredith Schlosser"
        },]
    },
}
</script>

<style lang="scss" scoped>
.contact-container{
    display: flex;
    justify-content: center;
    .photo{
        display:flex;
        flex-direction: column;
        margin-right: 20px;
        img{
            margin-top: 20px;
            max-width: 300px;
        }
        a{
            text-align: left;
        }
    }
    .contact-info{
        display: flex;
        flex-direction: column;
        align-content: flex-start;
        justify-content: flex-start;
        text-align: left;
        margin-left: 20px;
        margin-top: 80px;
        h3{
            text-transform: uppercase;
            margin-bottom: 0;
        }
        h6{
            margin: 0;
            margin-bottom: 30px;
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 1px;
        }
        .contact-anchor{
            color: #A9976E;
            margin: 30px 0;
            font-size: 28px;
            font-weight: 700;
            font-family: "Oswald", Helvetica, Arial, sans-serif;
            line-height: 40px;
        }
        .social-icon{
            color: #A9976E;
        }
        span{
            font-family: "Oswald", Helvetica, Arial, sans-serif;
            font-weight: 500;
            font-size: 28px;
            line-height: 40px;
        }
        .socials{
            .social-icon{
                color: #000;
                margin: 0;
                margin-right: 25px;
            }
        }
    }
}

.star-badge{
    margin-top: 0 !important;
    max-width: 120px !important;
}

.badge{
    display: block;
    margin-top: 20px;
}

.social-container{
    .social-icon{
        opacity: 1;
        &:hover{
            opacity: 0.5;
        }
    }

}

@media (max-width: $mobile) {
    .contact-container{
        flex-direction: column-reverse;
        margin-top: 20px;
         .contact-info{
            margin: 0;
            text-align: center;
             span{
                 font-size: 20px;
             }
         }
         .photo{
            a{
                text-align: center;
            }
        }
    }
    .social-container{
        justify-content:  center;
    }
}
</style>
